import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';
import { sentenceCase } from 'change-case';

function RegistrationKeyDetailsPopup(props) {
  const { handleClose, data } = props;

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <h2 style={{ fontSize: 16, margin: 0 }}>Registration Key Details</h2>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Divider />
        <Grid container mt={0.5} rowSpacing={2} xs={12}>
          {data && (
            <>
              <Grid item xs={12}>
                <strong>Key Details</strong>
              </Grid>
              <Grid item container rowSpacing={1} xs={12}>
                <Grid item xs={6} pl={2}>
                  <span>Group Code</span>
                </Grid>
                <Grid item xs={6}>
                  <span>{data?.group_code || '--'}</span>
                </Grid>
                <Grid item xs={6} pl={2}>
                  <span>Secret</span>
                </Grid>
                <Grid item xs={6}>
                  <span>{data?.secret}</span>
                </Grid>
                <Grid item xs={6} pl={2}>
                  <span>Dealer</span>
                </Grid>
                <Grid item xs={6}>
                  <span>{data?.dealer?.name}</span>
                </Grid>
                <Grid item xs={6} pl={2}>
                  <span>Remarks</span>
                </Grid>
                <Grid item xs={6}>
                  <span>{data?.remarks || '--'}</span>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <strong>Subscription</strong>
              </Grid>
              <Grid item container rowSpacing={1} xs={12}>
                <Grid item xs={6} pl={2}>
                  <span>Name</span>
                </Grid>
                <Grid item xs={6}>
                  <span>{sentenceCase(data?.plans?.subscription?.key)}</span>
                </Grid>
                <Grid item xs={6} pl={2}>
                  <span>Validity</span>
                </Grid>
                <Grid item xs={6}>
                  <span>{data?.plans?.subscription?.validity} days</span>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <strong>Add-ons</strong>
              </Grid>
              <Grid item container rowSpacing={1} xs={12}>
                {(data?.plans?.add_ons || []).map((addon) => (
                  <>
                    <Grid item xs={6} pl={2}>
                      <span>{sentenceCase(addon?.key)}</span>
                    </Grid>
                    <Grid item xs={6}>
                      <span>{addon?.validity} days</span>
                    </Grid>
                  </>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        style={{ padding: '12px 22px', justifyContent: 'flex-end' }}
      >
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RegistrationKeyDetailsPopup;
