import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { sentenceCase } from 'change-case';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import styles from './styles.module.css';
import Loader from '../../utils/Loading';
import { Button, Paper, TablePagination } from '@mui/material';
import { ROUTES } from '../../../const';
import { AlertMessagesContext } from 'react-alert-messages';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import RegistrationKeyServices from '../../../services/RegistrationKeyServices';
import Info from '../../utils/Alert/Info';
import DataTable from '../../utils/DataTable';
import RegistrationKeyDetailsPopup from '../../popups/RegistrationKeyDetailsPopup';
import CsvHelper from '../../../helpers/CsvHelper';
import { saveAs } from 'file-saver';
import ImportExportIcon from '@material-ui/icons/ImportExport';

function RegistrationKey() {
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const groupCode = queryParams.get('group_code');

  const [loading, setLoading] = useState(false);
  const [isShowRefreshButton, setIsShowRefreshButton] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [count, setCount] = useState(0);
  const [keyDetailsData, setKeyDetailsData] = useState(null);
  const [keys, setKeys] = useState(null);

  const handleKeySearch = (value) => {
    if (value !== searchText) {
      setOffset(0);
      setSearchText(value);
      setIsShowRefreshButton(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const _offset = newPage * limit;
    setOffset(_offset);
    getKeys({ _offset });
  };

  const handleChangeRowsPerPage = (event) => {
    const _limit = +event.target.value;
    setLimit(_limit);
    getKeys({ _limit });
  };

  const toggleKeyDetailsTab = (data) => setKeyDetailsData(data);

  const handleCreateRegistrationKey = (rowData = '') => {
    history.push(ROUTES.EDIT_REGISTRATION_KEY.replace(':keyId', rowData.id));
  };

  const toCsvBtnPressed = async () => {
    const csv = CsvHelper.getString(keys, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `items-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const CSV_COLUMNS = {
    id: 'id',
    secret: 'secret',
    subscription: {
      title: 'Subscription',
      getValue: (item) => item?.plans?.subscription?.key,
    },
    subscription_validity: {
      title: 'Validity',
      getValue: (item) => item?.plans?.subscription?.validity,
    },
    addons: {
      title: 'Add-ons',
      getValue: (item) =>
        (item?.plans?.add_ons || [])
          .map((addon) => {
            if (addon?.key === 'ecs_terminal') {
              return `Ecs terminal - ${addon?.count}`;
            } else {
              return `${addon.key} - ${addon.validity}`;
            }
          })
          .join(', '),
    },
  };

  const getKeys = useCallback(
    async ({ _offset = offset, _limit = limit, search = searchText } = {}) => {
      setLoading(true);
      try {
        const params = {
          offset: _offset,
          limit: _limit,
          search,
        };
        const resp = await RegistrationKeyServices.getKeys(params);
        setCount(resp.count);
        setKeys(resp.data);
        setIsShowRefreshButton(false);
      } catch (error) {
        postAlertMessage({ text: error.message, type: 'failed' });
      }
      setLoading(false);
    },
    [limit, offset, searchText, postAlertMessage]
  );

  const getTableRowStyles = (row) => {
    return row?.is_active === false
      ? { backgroundColor: 'rgba(245, 39, 39, 0.2)' }
      : {};
  };

  const headerData = [
    {
      label: 'ID',
      id: 'id',
      type: 'text',
    },
    {
      label: 'Secret',
      id: 'secret',
      type: 'text',
    },
    {
      label: 'Shop ID',
      id: 'shop_id',
      type: 'text',
    },
    {
      label: 'Dealer',
      id: 'dealer.name',
      type: 'text',
    },
    {
      label: 'Subscription',
      id: 'subscription',
      type: 'callback',
      viewRender: (data) => sentenceCase(data?.plans?.subscription?.key || '-'),
    },
    {
      label: 'Group Code',
      id: 'group_code',
      type: 'text',
    },
    {
      label: 'Details',
      id: 'shopDetails',
      type: 'button',
      title: 'Details',
      clickHandler: toggleKeyDetailsTab,
    },
    {
      label: 'Edit',
      id: 'updateItems',
      type: 'button',
      title: 'Edit',
      clickHandler: handleCreateRegistrationKey,
    },
  ];

  useEffect(() => {
    getKeys({ _offset: null, _limit: null, search: groupCode });
    setSearchText(groupCode);
    //eslint-disable-next-line
  }, []);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loading} />
      <div className={styles.titleSec}>
        <span className={styles.title}>
          Registration Keys<span className={styles.menuTitle}>Management</span>
        </span>
        <div style={{ justifyContent: 'flex-end' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Export As</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={toCsvBtnPressed}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            CSV
          </Button>
        </div>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <span className={styles.subTitle}>Registration keys</span>
          </div>

          <div className={styles.filerInputSec}>
            <Link
              to={ROUTES.NEW_REGISTRATION_KEY}
              style={{ textDecorationColor: 'transparent' }}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ backgroundColor: '#ff851b' }}
                className={styles.actionBtn}
              >
                <NoteAddIcon className={styles.actionBtnIcon} />
                New Key
              </Button>
            </Link>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  handleKeySearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="Search keys"
              />
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={getKeys}
                disabled={isShowRefreshButton === false}
              >
                Refresh
              </Button>
            </div>
          </div>
        </div>
      </div>
      {keys &&
        (keys && keys.length ? (
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <DataTable
              columns={headerData}
              rows={keys}
              getRowStyles={getTableRowStyles}
            />
            <TablePagination
              rowsPerPageOptions={[50, 100]}
              component="div"
              count={count}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        ) : (
          <Info
            title={'No keys to list'}
            content={
              'You have no keys to list with current filter configuration. Please clear the filters or create a key'
            }
          />
        ))}
      {keyDetailsData && (
        <RegistrationKeyDetailsPopup
          data={keyDetailsData}
          handleClose={() => setKeyDetailsData(null)}
        />
      )}
    </div>
  );
}

export default withConsoleBase(RegistrationKey);
