import { Button } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import ShopServices from '../../../services/ShopServices';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Loader from '../../utils/Loading';
import Info from '../../utils/Alert/Info';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import setDelay from '../../../helpers/LoadingDelay';
import { ROUTES, SUBSCRIPTIONS } from '../../../const';
import { Link, useHistory } from 'react-router-dom';
import { Paper, TablePagination } from '@mui/material';
import ShopDetailsPopup from '../../popups/ShopDetails';
import AlertHelper from '../../../helpers/AlertHelper';
import Error from '../../utils/Alert/Error';
import ShopResetPopup from '../../popups/ShopReset';
import { AlertMessagesContext } from 'react-alert-messages';

function Shops() {
  const [shops, setShops] = useState();
  const [shopsFiltered, setShopsFiltered] = useState('');
  const [searchText, setSearchText] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(0);
  const [shopDetailsData, setShopDetailsData] = useState(null);
  const [errorMsg, setErrorMsg] = useState();
  const [resetType, setResetType] = useState(null);
  const [isShowRefreshButton, setIsShowRefreshButton] = useState(false);

  const { postAlertMessage } = useContext(AlertMessagesContext);
  const history = useHistory();
  const handleEditShop = (rowData = '') => {
    history.push(ROUTES.EDIT_SHOP.replace(':shopId', rowData.id));
  };

  const toggleDetailsTab = (data) => setShopDetailsData(data);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const _offset = newPage * limit;
    setOffset(_offset);
    getShops({ _offset });
  };

  const handleChangeRowsPerPage = (event) => {
    const _limit = +event.target.value;
    setLimit(_limit);
    getShops({ _limit });
  };

  const handleShopSearch = (value) => {
    if (value !== searchText) {
      setOffset(0);
      setSearchText(value);
      setIsShowRefreshButton(true);
    }
  };

  const getTableRowStyles = (row) => {
    if (row?.is_active === false) {
      return { backgroundColor: 'rgba(245, 39, 39, 0.2)' };
    }

    const currentDate = new Date();
    const fiveDaysToEnd = new Date(currentDate);
    fiveDaysToEnd.setDate(currentDate.getDate() + 5);
    const subscription_end_date = new Date(row?.subscription_end_date);

    if (!row.subscription_end_date || subscription_end_date < currentDate) {
      return { backgroundColor: 'rgba(245, 39, 39, 0.2)' };
    }

    if (
      subscription_end_date > currentDate &&
      subscription_end_date < fiveDaysToEnd
    ) {
      return { backgroundColor: 'rgba(245, 169, 39, 0.2)' };
    }

    return {};
  };

  const getShops = useCallback(
    async ({ _offset = offset, _limit = limit, search = searchText } = {}) => {
      setLoadingIndicator(true);
      try {
        const params = {
          offset: _offset,
          limit: _limit,
          search,
        };
        const res = await ShopServices.getShops(params);
        setShopsFiltered(res.data);
        setCount(res.count);
        setShops(res.data);
        setIsShowRefreshButton(false);
      } catch (error) {
        AlertHelper.setShortMessage(setErrorMsg, error.message);
      }
      setDelay(setLoadingIndicator);
    },
    [limit, offset, searchText]
  );

  const handleResetShop = async (data, type) => {
    setLoadingIndicator(true);
    try {
      if (type === 'HARD_RESET') {
        await ShopServices.hardResetShop(data?.id);
        postAlertMessage({
          text: 'Shop reseted successfully',
          type: 'success',
        });
      } else {
        await ShopServices.softResetShop(data?.id);
        postAlertMessage({
          text: 'Shop reseted successfully',
          type: 'success',
        });
      }
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setDelay(setLoadingIndicator);
  };

  const headerData = [
    {
      label: 'ID',
      id: 'id',
      type: 'text',
    },
    {
      label: 'Shop name',
      id: 'shop_name',
      type: 'text',
    },
    {
      label: 'Phone',
      id: 'mobile_no',
      type: 'text',
    },
    {
      label: 'Subscription',
      id: 'subscription',
      type: 'callback',
      viewRender: (data) => SUBSCRIPTIONS[data?.subscription],
    },
    {
      label: 'Subscription End',
      id: 'subscription_end_date',
      type: 'text',
    },
    {
      label: 'Details',
      id: 'shopDetails',
      type: 'button',
      title: 'Details',
      clickHandler: toggleDetailsTab,
    },
    {
      label: 'Edit',
      id: 'updateItems',
      type: 'button',
      title: 'Edit',
      clickHandler: handleEditShop,
    },
  ];
  useEffect(() => {
    getShops();
    //eslint-disable-next-line
  }, []);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Shops<span className={styles.menuTitle}>Management</span>
        </h2>
      </div>

      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <span className={styles.subTitle}>shops</span>
          </div>

          <div className={styles.filerInputSec}>
            <Link
              to={ROUTES.NEW_SHOP}
              style={{ textDecorationColor: 'transparent' }}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ backgroundColor: '#ff851b' }}
                className={styles.actionBtn}
              >
                <NoteAddIcon className={styles.actionBtnIcon} />
                New Shop
              </Button>
            </Link>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  handleShopSearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="Search shops"
              />
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={getShops}
                disabled={isShowRefreshButton === false}
              >
                Refresh
              </Button>
            </div>
          </div>
        </div>
      </div>

      {errorMsg && (
        <div className={styles.marginTop}>
          <Error title={errorMsg} />
        </div>
      )}

      {shops &&
        (shopsFiltered && shopsFiltered.length ? (
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <DataTable
              columns={headerData}
              rows={shopsFiltered || shops}
              getRowStyles={getTableRowStyles}
            />
            <TablePagination
              rowsPerPageOptions={[20, 50]}
              component="div"
              count={count}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        ) : (
          <Info
            title={'No shops to list'}
            content={
              'You have no shops to list with current filter configuration. Please clear the filters or create a shop'
            }
          />
        ))}
      {shopDetailsData && (
        <ShopDetailsPopup
          data={shopDetailsData}
          handleClose={() => setShopDetailsData(null)}
          resetShop={(type) => setResetType(type)}
        />
      )}
      {resetType && (
        <ShopResetPopup
          data={shopDetailsData}
          handleClose={() => setResetType(null)}
          handleOkay={handleResetShop}
          resetType={resetType}
        />
      )}
    </div>
  );
}

export default withConsoleBase(Shops);
