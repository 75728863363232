import BackendService from './BackendService';

async function getShops(params) {
  return BackendService.getData(
    `/admin/shops/`, {}, params
  );
}
async function getShop(id) {
  return BackendService.getData(`/admin/shops/${id}/`);
}
async function createShop(data) {
  return BackendService.postData('/admin/shops/', data);
}
async function updateShop(id, data = {}) {
  return BackendService.patch(`/admin/shops/${id}/`, data);
}
async function deleteShop(id) {
  return BackendService.destroy(`/admin/shops/${id}/`, '');
}
async function hardResetShop(id) {
  return BackendService.destroy(`/admin/shops/${id}/hard-reset/`, '');
}
async function softResetShop(id) {
  return BackendService.destroy(`/admin/shops/${id}/soft-reset/`, '');
}

const ShopServices = {
  getShop,
  getShops,
  createShop,
  updateShop,
  deleteShop,
  hardResetShop,
  softResetShop,
};

export default ShopServices;
