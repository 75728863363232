import BackendService from './BackendService';

async function getKeys(params) {
  return BackendService.getData(`/registration-keys/`, {}, params);
}
async function getKey(id) {
  return BackendService.getData(`/registration-keys/${id}/`);
}
async function createKey(data) {
  return BackendService.postData('/registration-keys/', data);
}
async function updateKey(id, data = {}) {
  return BackendService.patch(`/registration-keys/${id}/`, data);
}

const RegistrationKeyServices = {
  getKeys,
  getKey,
  createKey,
  updateKey,
};

export default RegistrationKeyServices;
