import React, { useContext } from "react";
import NavBar from "../NavBar";
import TitleBar from "../TitleBar";
import styles from "./styles.module.css";
import Loader from "../Loading";
import { STORAGE_KEYS } from "../../../const";
import { Grid } from "@material-ui/core";
import { selectedTabContext } from "../../../Context/SelectedTabContext";

function withConsoleBase(ConsoleComponent) {
  return () => {
    const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
    const { showMenu, setShowMenu } = useContext(selectedTabContext);
    const windowWidth = window.innerWidth;
    if (windowWidth >= 600) {
      setShowMenu(true);
    }
    return (
      <>
        {token !== "-1" ? (
          <>
            <TitleBar />
            <Grid container className={styles.ContentsWrapper}>
              {showMenu && (
                <Grid item className={styles.NavbarWrapper}>
                  <NavBar />
                </Grid>
              )}
              <Grid
                item
                className={
                  showMenu ? styles.ContentsArea : styles.ContentsAreaFull
                }
              >
                <div className={styles.ContentsView}>
                  <ConsoleComponent />
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          <Loader isOpen={true} />
        )}
      </>
    );
  };
}

export default withConsoleBase;
