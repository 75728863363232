import React, { createContext, useState } from "react";
import { useLocation } from "react-router";

export const selectedTabContext = createContext();

export default function SelectedTabProvider(props) {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(
    location?.pathname.split("/")[2] || "dashboard"
  );
  const [showMenu, setShowMenu] = useState(false);

  return (
    <selectedTabContext.Provider
      value={{ selectedTab, setSelectedTab, showMenu, setShowMenu }}
    >
      {props.children}
    </selectedTabContext.Provider>
  );
}
