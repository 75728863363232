import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import styles from './styles.module.css';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Grid } from '@material-ui/core';
import { ROUTES } from '../../../const';
import { selectedTabContext } from '../../../Context/SelectedTabContext';
import StoreIcon from '@mui/icons-material/Store';
import KeyIcon from '@mui/icons-material/Key';
import AuthService from '../../../services/AuthService';
const tabStyleActive = {
  backgroundColor: '#03204C',
  color: '#fff',
};

const tabStyle = {};
export default function NavBar() {
  const { selectedTab, setSelectedTab } = useContext(selectedTabContext);
  const handleSelectedTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const initiateLogout = async (e) => {
    await AuthService.logout();
    window.location.reload();
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={styles.navBar}>
        <div>
          <Link
            to={ROUTES.SHOPS}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={selectedTab === 'shops' ? tabStyleActive : tabStyle}
              onClick={() => {
                handleSelectedTabChange('shops');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <StoreIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Shops</span>
            </div>
          </Link>
          <Link
            to={ROUTES.REGISTRATION_KEY}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={
                selectedTab === 'registration_key' ? tabStyleActive : tabStyle
              }
              onClick={() => {
                handleSelectedTabChange('registration_key');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <KeyIcon fontSize="small" className={styles.navBarIconColor} />
              </span>
              <span className={styles.title}>Reg. Keys</span>
            </div>
          </Link>
        </div>
        <Link
          key="logout"
          className={styles.logoutWrapper}
          onClick={initiateLogout}
        >
          <LogoutOutlinedIcon />
          <span className={styles.navLinkText}>Logout</span>
        </Link>
      </Grid>
    </Grid>
  );
}
