import React from 'react';
import styles from './styles.moduel.css';
import CloseIcon from '@material-ui/icons/Close';
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { SUBSCRIPTIONS } from '../../const';

function ShopDetails(props) {
  const { handleClose, data, resetShop } = props;
  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={styles.title} id="alert-dialog-title">
        <span>Shop Details</span>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          spacing={2}
          className={styles.inputRow}
          alignItems="center"
        >
          <Grid item xs={5} className={styles.inputLabelContainer}>
            <span>Id</span>
          </Grid>
          <Grid item xs={7}>
            <span style={{ color: '#3f8dce' }}>{data?.id || '-'}</span>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className={styles.inputRow}
          alignItems="center"
        >
          <Grid item xs={5} className={styles.inputLabelContainer}>
            <span>Shop name</span>
          </Grid>
          <Grid item xs={7} className={styles.data}>
            <span style={{ color: '#3f8dce' }}>{data?.shop_name || '-'}</span>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className={styles.inputRow}
          alignItems="center"
        >
          <Grid item xs={5} className={styles.inputLabelContainer}>
            <span>Subscription</span>
          </Grid>
          <Grid item xs={7}>
            <span style={{ color: '#3f8dce' }}>
              {SUBSCRIPTIONS[data?.subscription] || '-'}
            </span>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className={styles.inputRow}
          alignItems="center"
        >
          <Grid item xs={5} className={styles.inputLabelContainer}>
            <span>Dealer</span>
          </Grid>
          <Grid item xs={7}>
            <span style={{ color: '#3f8dce' }}>{data?.dealer?.name || '-'}</span>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className={styles.inputRow}
          alignItems="center"
        >
          <Grid item xs={5} className={styles.inputLabelContainer}>
            <span>Expense validity</span>
          </Grid>
          <Grid item xs={7}>
            <span style={{ color: '#3f8dce' }}>
              {data?.expense_validity || '-'}
            </span>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className={styles.inputRow}
          alignItems="center"
        >
          <Grid item xs={5} className={styles.inputLabelContainer}>
            <span>Image Support validity</span>
          </Grid>
          <Grid item xs={7}>
            <span style={{ color: '#3f8dce' }}>
              {data?.image_support_validity || '-'}
            </span>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className={styles.inputRow}
          alignItems="center"
        >
          <Grid item xs={5} className={styles.inputLabelContainer}>
            <span>Kot validity</span>
          </Grid>
          <Grid item xs={7}>
            <span style={{ color: '#3f8dce' }}>
              {data?.kot_validity || '-'}
            </span>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className={styles.inputRow}
          alignItems="center"
        >
          <Grid item xs={5} className={styles.inputLabelContainer}>
            <span>Smart scale validity</span>
          </Grid>
          <Grid item xs={7}>
            <span style={{ color: '#3f8dce' }}>
              {data?.smart_scale_validity || '-'}
            </span>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className={styles.inputRow}
          alignItems="center"
        >
          <Grid item xs={5} className={styles.inputLabelContainer}>
            <span>Mobile no</span>
          </Grid>
          <Grid item xs={7}>
            <span style={{ color: '#3f8dce' }}>{data?.mobile_no || '-'}</span>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className={styles.inputRow}
          alignItems="center"
        >
          <Grid item xs={5} className={styles.inputLabelContainer}>
            <span>Subscription End Date</span>
          </Grid>
          <Grid item xs={7}>
            <span style={{ color: '#3f8dce' }}>
              {data?.subscription_end_date || '-'}
            </span>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className={styles.inputRow}
          alignItems="center"
        >
          <Grid item xs={5} className={styles.inputLabelContainer}>
            <span>Currency</span>
          </Grid>
          <Grid item xs={7}>
            <span style={{ color: '#3f8dce' }}>{data?.currency || '-'}</span>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        style={{ padding: '12px 22px', justifyContent: 'space-between' }}
      >
        <div>
          <Button variant="contained" color="secondary" onClick={() => resetShop('SOFT_RESET')}>
            Soft Reset
          </Button>
          <Button variant="contained" style={{marginLeft: '12px'}} color="secondary" onClick={() => resetShop('HARD_RESET')}>
            Hard Reset
          </Button>
        </div>
        <div>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default ShopDetails;
