import React, { useState, useContext, useEffect } from 'react';
import styles from './styles.module.css';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { Grid, TextField, MenuItem, Button } from '@material-ui/core';
import Loader from '../../utils/Loading';
import { AlertMessagesContext } from 'react-alert-messages';
import { TERMINAL_TYPES } from '../../../const';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import TerminalServices from '../../../services/TerminalServices';

function CreateTerminal() {
  const history = useHistory();
  const { shopId } = useParams();
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const handleCancel = () => {
    history.goBack();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await TerminalServices.createTerminal(data);
      postAlertMessage({
        text: 'Terminal created successfully',
        type: 'success',
      });
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (shopId) {
      setData({ ...data, shop_id: shopId });
    }
    //eslint-disable-next-line
  }, [shopId]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loading} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Terminal<span className={styles.menuTitle}>Create</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Shop Id</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="text"
              value={data?.shop_id}
              onChange={(e) => {
                setData({ ...data, shop_id: e.target.value });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Type</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              select
              size="small"
              value={data?.type}
              variant="outlined"
              fullWidth
              color="primary"
              onChange={(event) => {
                setData({ ...data, type: event.target.value });
              }}
            >
              {Object.keys(TERMINAL_TYPES).map((type) => (
                <MenuItem value={type}>{TERMINAL_TYPES[type]}</MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Name</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="text"
              value={data?.name}
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
            />
          </Grid>
        </Grid>

        {data.type === 'ecs' && (
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={12}
              md={3}
              spacing={2}
              justifyContent="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>MAC ID</h3>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                type="text"
                value={data?.mac_id}
                onChange={(e) => {
                  setData({ ...data, mac_id: e.target.value });
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Next sale number to load</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="text"
              value={data?.next_sale_number_to_load}
              onChange={(e) => {
                setData({ ...data, next_sale_number_to_load: e.target.value });
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          className={styles.submitSection}
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="secondary"
            className={styles.closeBtn}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: '#00a65a', marginLeft: '10px' }}
            onClick={handleSubmit}
          >
            {'Create Terminal'}
          </Button>
        </Grid>
      </div>
    </div>
  );
}

export default withConsoleBase(CreateTerminal);
