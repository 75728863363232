export const BACKEND_URL =
  process.env.REACT_APP_API_BASE_URL || 'https://api-pos-legacy.sanoft.com/api';
// export const BACKEND_URL = 'https://yscloud.in/backend/api';
// export const BACKEND_URL = 'http://127.0.0.1:8000/api';

export const STORAGE_KEYS = {
  TOKEN: 'x-auth-token',
  USER_ID: 'x-auth-user-id',
  FIRST_NAME: 'x-first-name',
  LAST_NAME: 'x-last-name',
  USERNAME: 'x-username',
};

export const HEADERS = {
  AUTH: 'Authorization',
};

export const ROUTES = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  ANY: '/',
  CONSOLE: '/console',

  SHOPS: '/console/shops',
  NEW_SHOP: '/console/new-shop',
  EDIT_SHOP: '/console/shops/:shopId',
  REGISTRATION_KEY: '/console/registration-keys',
  NEW_REGISTRATION_KEY: '/console/registration-keys/new-key',
  EDIT_REGISTRATION_KEY: '/console/registration-keys/:keyId',
  CREATE_TERMINAL: '/console/shops/:shopId/terminal',
  PAGE_404: '/page-404',
};

export const GST_PREFERENCES = [
  { name: 'Non GST', id: 0 },
  { name: 'Excluding GST', id: 1 },
  { name: 'Including GST', id: 2 },
];
export const SUBSCRIPTIONS = {
  4: 'standard',
  2: 'premium',
  3: 'platinum',
};
export const DEALERS = {
  1: 'Scangle',
  2: 'Weighvox Dubai',
  3: 'Weighvox Oman',
  4: 'Sanoft',
  5: 'ALT',
  6: 'Dynamic',
  7: 'Saudi',
  8: 'Trass India',
  9: 'Avoquick',
  10: 'Yesweigh',
  11: 'Kraus',
  12: 'Elmass',
  13: 'HMS',
  14: 'D Sonic',
  15: 'Venkatachalam',
  16: 'Victory',
};
export const CURRENCIES = ['₹', 'RO', 'AED', 'SAR'];

export const TERMINAL_TYPES = { pos: 'POS', ecs: 'ECS' };
