import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import AlertMessagesProvider from 'react-alert-messages';
import React from 'react';
import './App.css';
import LoginPage from './components/pages/Login/Login';
import PageNotFound from './components/pages/Error/PageNotFound';
import PrivateRoute from './components/utils/PrivateRoute';
import SelectedTabProvider from './Context/SelectedTabContext';
import ShopsPage from './components/pages/Shops/Shops';
import NewShopPage from './components/pages/Shops/NewShop';
import RegistrationKeyPage from './components/pages/RegistrationKey/RegistrationKey';
import NewRegistrationKeyPage from './components/pages/RegistrationKey/NewRegistrationKey';
import CreateTerminalPage from './components/pages/Terminal/CreateTerminal';
import { ROUTES } from './const';

function App() {
  return (
    <AlertMessagesProvider>
      <Router>
        <SelectedTabProvider>
          <Switch>
            <Route exact path={ROUTES.ANY} component={LoginPage} />
            <Route exact path={ROUTES.CONSOLE} component={LoginPage} />
            <Route exact path={ROUTES.LOGIN} component={LoginPage} />
            <Route exact path={ROUTES.PAGE_404} component={PageNotFound} />
            <PrivateRoute exact path={ROUTES.SHOPS} component={ShopsPage} />
            <PrivateRoute
              exact
              path={ROUTES.NEW_SHOP}
              component={NewShopPage}
            />
            <PrivateRoute
              exact
              path={ROUTES.EDIT_SHOP}
              component={NewShopPage}
            />
            <PrivateRoute
              exact
              path={ROUTES.REGISTRATION_KEY}
              component={RegistrationKeyPage}
            />
            <PrivateRoute
              exact
              path={ROUTES.NEW_REGISTRATION_KEY}
              component={NewRegistrationKeyPage}
            />
            <PrivateRoute
              exact
              path={ROUTES.EDIT_REGISTRATION_KEY}
              component={NewRegistrationKeyPage}
            />
            <PrivateRoute
              exact
              path={ROUTES.CREATE_TERMINAL}
              component={CreateTerminalPage}
            />
            <Route path="/*">
              <Redirect to={ROUTES.PAGE_404} />
            </Route>
          </Switch>
        </SelectedTabProvider>
      </Router>
    </AlertMessagesProvider>
  );
}

export default App;
