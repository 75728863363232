import BackendService from './BackendService';

async function createTerminal(data) {
  return BackendService.postData('/admin/terminals/', data);
}

const ShopServices = {
  createTerminal,
};

export default ShopServices;
