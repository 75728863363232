import BackendService from './BackendService';

async function getDealers() {
  return BackendService.getData(`/admin/dealers/`);
}

const ShopServices = {
  getDealers,
};

export default ShopServices;
