import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import {
  TextField,
  Grid,
  InputAdornment,
  Box,
  Paper,
  Button,
} from '@material-ui/core';
import Loader from '../../utils/Loader';
import AuthService from '../../../services/AuthService';
import { useHistory } from 'react-router-dom';
import { STORAGE_KEYS, ROUTES } from '../../../const';
import usernameIcon from './person.svg';
import passwordIcon from './lock.svg';

export default function Login(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const history = useHistory();
  
  useEffect(() => {
    const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
    if (token) {
      history.push(ROUTES.SHOPS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginBtnPressed = async (e) => {
    if (!username || !password) {
      setErrorMessage('Username and Password should not be empty..');
      setTimeout(() => {
        setErrorMessage(null);
      }, 2000);
      return;
    }
    setLoadingIndicator(true);
    const error = await AuthService.login(username, password);
    setLoadingIndicator(false);
    if (error) {
      setErrorMessage('Failed to login!');
    } else {
      history.push(ROUTES.SHOPS);
    }
  };

  return (
    <Grid className={styles.mainDiv}>
      <Box container className={styles.outerBoxFrame}>
      
        <Grid
          style={{ boxShadow: 3 }}
          item
          xs={12}
          sm={12}
          className={styles.formBoxFrame}
        >
          <Grid>
            <Grid className={styles.welcome}>
              <span style={{ color: '#3F8DCE' }}>SANOFT</span>
              <span style={{ color: '#3C465B', marginLeft: 10 }}>POS ADMIN</span>
            </Grid>
            <div className={styles.textCenter}>
              <TextField
                name="username"
                size="small"
                label="Username"
                type="text"
                placeholder="type your username"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={usernameIcon} width="18" height="18" />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
                autoComplete="current-username"
                color="primary"
                fullWidth
              />
            </div>
            <div className={styles.textCenter}>
              <TextField
                name="password"
                size="small"
                label="Password"
                type="password"
                margin="normal"
                placeholder="type your password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={passwordIcon} width="18" height="18" />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                autoComplete="current-password"
                color="primary"
                fullWidth
              />
            </div>
            {errorMessage && (
              <Paper className={styles.errorMsg}>
                <span>{errorMessage}</span>
              </Paper>
            )}
            <div className={styles.loginLoader}>
              {loadingIndicator && <Loader />}
            </div>
            <Grid className={styles.textCenter}>
              <Button
                className={styles.loginButton}
                variant="contained"
                color="secondary"
                type="submit"
                onClick={loginBtnPressed}
                disabled={loadingIndicator}
                fullWidth
                style={{ backgroundColor: '#3f8dce' }}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
